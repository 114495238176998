$(document).foundation();

$(document).ready(function () {

	// Ouvre le sous menu qui contient la page en cours
	$('.current-menu-ancestor').attr('aria-expanded', 'true').children('ul.sub-menu').addClass('is-active').attr('aria-hidden', 'false').show();

	// Dans les sous-sites des services
	$('.current_page_ancestor button').attr('aria-expanded', 'true');
	$('.current_page_ancestor').children('ul.submenu').addClass('is-active').attr('aria-hidden', 'false').show();

	$('.current_page_item.is-accordion-submenu-parent .submenu-toggle').attr('aria-expanded', 'true');
	$('.current_page_item.is-accordion-submenu-parent .submenu').addClass('is-active').attr('aria-hidden', 'false').show();

	$(".blocks-gallery-item a, .wp-block-image a").fancybox();

	$(window).on('changed.zf.mediaquery', function (event, newSize, oldSize) {
		// newSize is the name of the now-current breakpoint, oldSize is the previous breakpoint
		if ((oldSize == 'medium' && newSize == 'small') || (oldSize == 'small' && newSize == 'medium')) {
			$('#offCanvas').foundation('close');
			// $('#btnMenu,#toggleMenu').data('aria-expanded','false');
			if ($('#btnMenu').data('aria-expanded') == 'true')
				$('#wrapper_menu').foundation('toggle');
			$('#btnMenu').data('aria-expanded', 'false');
		}
	});

	$('#btnSearch').on('click', function () {
		$('#s').focus();
	});

	if ($('body').hasClass('home')) {
		// Slider actualités
		const swiper = new Swiper('#home_slider .swiper', {

			loop: true,

			// If we need pagination
			pagination: {
				el: '.swiper-pagination',
			},

			// Navigation arrows
			navigation: {
				nextEl: '#home_slider .swiper-button-next',
				prevEl: '#home_slider .swiper-button-prev',
			},

		});
		// Slider chiffres
		const swiper2 = new Swiper('#home_slider_chiffres', {
			slidesPerView: 2,
			spaceBetween: 10,
			breakpoints: {
				1000: {
					slidesPerView: 4,
					spaceBetween: 40
				}
			},
			navigation: {
				nextEl: '#home_slider_chiffres .swiper-button-prev',
				prevEl: '#home_slider_chiffres .swiper-button-next',
			},
		});

		var $slick_slider = $('.slick-slider');
		var settings = {
			swipe: false,
			draggable: false,
			infinite: true,
			slidesToShow: 3,
			slidesToScroll: 3,
			arrows: false,
			responsive: [
				{
					breakpoint: 640,
					settings: "unslick"
				}
			]
		};

		$slick_slider.slick(settings);

		$(window).on('resize', function () {
			if (Foundation.MediaQuery.current == 'small') {
				if ($slick_slider.hasClass('slick-initialized')) {
					$slick_slider.slick('unslick');
				}
				return
			}

			if (!$slick_slider.hasClass('slick-initialized')) {
				return $slick_slider.slick(settings);
			}
		});

		$('a[data-slide]').click(function (e) {
			e.preventDefault();
			$('a[data-slide]').removeClass('current');
			$(this).addClass('current');
			var slideno = $(this).data('slide');
			$('.slick-slider').slick('slickGoTo', slideno - 1);
		});

		// 	  $('.vignette-service a').append('<svg class="more" enable-background="new 0 0 55 55" version="1.1" viewBox="0 0 55 55" xml:space="preserve" xmlns="http://www.w3.org/2000/svg"><style type="text/css">.st0{fill:none;stroke:#ffffff;stroke-width:3;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}</style><line class="st0 draw-arrow tail-1" x1="53" x2="27.5" y1="27.5" y2="53"/><line class="st0 draw-arrow tail-2" x1="27.5" x2="53" y1="2" y2="27.5"/><line class="st0 draw-arrow" x1="2" x2="53" y1="27.5" y2="27.5"/></svg>');
	}

	function init_filter() {
		$('.filter').on('change', function (e) {

			if (this.value != '') {
				$('#main .filter_content').show();
				$('#main .filter_content').not('.filter_' + this.value).hide();
			}
			else
				$('#main .filter_content').show();
		});
	}

	if ($('.filter').length) {
		init_filter();
	}

	/*
	  if( $('body').hasClass('.page-service') ){
		  $('.current_page_item.has-submenu-toggle .submenu-toggle').trigger('click');
	  }
	*/

});


